import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Typography, Grid, Box, Divider, Button} from '@material-ui/core'
import Navbar from './NavBar'
import usf from "../images/usf128px.png"
import scc from "../images/scc2.png"
import google from "../images/google-logo.png"
import javascript from "../images/javascript-logo2.png"
import html from "../images/HTML5_2.png"
import css3 from "../images/css3.png"
import linux from "../images/linux.png"
import git from "../images/git.png"
import mysql from "../images/mysql2.png"
import nodejs from "../images/node-js.png"
import npm from "../images/npmlogo.png"
import SendIcon from "@material-ui/icons/Send"

const useStyles = makeStyles(theme =>({
    mainContainer: {
        margin: "4rem auto",
        width: "70%",
        color: "tan",
        textIndent: "1rem", 
    },

    textContainer: {
        padding: "20px", 
         
    },

    imageStyles: {
        marginLeft: "20px", 
        marginRight: "20px",
        display: "block",
        
    },

    skillsContainer: {
        width: "100%",
        marginLeft: "20px",
    },

    boxes: {

        marginTop: "3rem",       
    },

    boxCaption: {        
        fontSize: "1rem",
        marginTop: "1.5rem"
    },

    button: {
        marginTop: "1rem",
        color: "tomato",
        borderColor: "tomato",   
        position: "fixed",
        bottom: 30,
        right: 30,
    },

    paragraph: {
        textIndent: "1rem",
    }

}))

const About = () => {

const classes = useStyles()

    return (
        <div>
            <Navbar /> 
            <Box className={classes.mainContainer}>                    
                <Grid container spacing={3} direction="column"> 
                    <Grid item xs={12} >
                        <Typography 
                        variant="h4"
                        
                        style={{
                            
                            marginTop: "3rem",
                            marginBottom: "6rem",
                            textAlign: "center",
                            fontWeightLight: "100"  
                            }}
                        >
                        Bio
                        </Typography>
                        <Typography  className="paragraph" variant="p" >
                            As a Web Developer, I have demonstrated the ability to plan and execute a project form the initial stage all the way through to final delivery. Whether I am working on small projects, or large campaigns with a full team, I am always excited to collaborate with others to  solve new challenges. My favorite projects are those that involve the need for me to learn a new technology. 
                        </Typography >
                        <br/>
                        <br/>
                        <Typography className="paragraph" variant="p" >
                            I posses 3+ years of experiences with various web developement technologies such as HTML, CSS, jQuery, PHP, and ES6. I have experience doing freelance projects as well as projects in an acedemic environment. I have managed schedules and creative reviews to ensure timelines and deadlines are met, and when necessary I will step in where I am needed to ensure the project is completed. 
                        </Typography>

                    </Grid>
                    <a href="Resume.pdf" download>
                        <Button 
                            variant="outlined"                            
                            endIcon={<SendIcon />}
                            className={classes.button}
                            type="submit"                            
                            size="small"
                            fontSize="0.8rem"
                            >
                            Download Resume
                        </Button>
                    </a>
                    
                        
                    <Grid item xs={12} >
                        <Typography 
                        variant="h4"
                        
                        style={{
                            
                            marginTop: "3rem",
                            marginBottom: "6rem",
                            textAlign: "center",
                            fontWeightLight: "100"  
                            }}
                        >
                        Education
                        </Typography>
                    </Grid>
                                         
                    <Grid item container spacing={6} direction="row">
                        <Grid item xs={12} sm={3} justify="flex-start">
                            <Button href="https://it.seattlecentral.edu/programs/web-development" target="_blank">
                            <img 
                                src={scc} 
                                alt="scc" 
                                className={classes.imageStyles}
                            />
                            </Button>
                        </Grid>   
                        <Grid item xs={12} sm={9} justify="center">
                            <Box component="div" className={classes.textContainer}>
                                <Typography variant="h6">
                                Seattle Central College
                                </Typography>
                                <Typography variant="h6" >
                                Associate's Degree, Web Development and Mobile App Development
                                </Typography>
                                <Typography >
                                January 2020 - PRESENT
                                </Typography>
                            </Box>
                        </Grid>                                     
                    </Grid>
                    <Grid item  container spacing={6} direction="row" >
                        <Grid item item xs={12} sm={3} justify="flex-start">
                            <Button 
                            href="https://grow.google/programs/it-support/" 
                            target="_blank"
                            >
                            <img 
                                src={google} 
                                alt="google" 
                                className={classes.imageStyles}
                            />
                            </Button>
                        </Grid>   
                        <Grid item xs={12} sm={9} justify="center">
                            <Box component="div" className={classes.textContainer}>
                                <Typography variant="h6">
                                Google IT Support Professional Certificate
                                </Typography>
                                <Typography variant="h6">
                                Online Certificate
                                </Typography>
                                <Typography >
                                Completion February 2019
                                </Typography>
                            </Box>
                        </Grid>                                     
                    </Grid>
                    <Grid item container spacing={6} direction="row" >
                        <Grid item xs={12} sm={3} justify="flex-start">
                            <Button href="https://www.usf.edu/" target="_blank">
                            <img 
                                src={usf} 
                                alt="usf" 
                                className={classes.imageStyles}
                            />
                            </Button>
                        </Grid>   
                        <Grid item xs={12} sm={9} justify="center">
                            <Box component="div" className={classes.textContainer}>
                                <Typography variant="h6">
                                University of South Florida
                                </Typography>
                                <Typography variant="h6">
                                Bachelor's Degree, Political Science and Government
                                </Typography>
                                <Typography>
                                Graduation: 2014
                                </Typography>
                            </Box>
                        </Grid>                                     
                    </Grid>
                </Grid>
                <Divider variant="middle" /> 

                <Grid item container spacing={3} className={classes.skillsContainer} direction="column" alignItems="center">
                    <Grid item xs={12} direction="row">
                        <Typography variant="h4" style={{
                           
                            marginTop: "8rem",
                            marginBottom: "6rem",
                            fontWeight: "100",
                            textAlign: "center"    
                            }}
                        >
                        Experience with:
                        </Typography>
                    </Grid>
                    {/* First Row */}
                    <Grid item container spacing={3} direction="row" justify="space-evenly" >
                        <Grid 
                        item xs={12} sm={6} md={4} lg={3}
                        className={classes.boxes}
                        >
                            <img src={html} alt="html_logo"/>
                            <Typography className={classes.boxCaption}>HTML5</Typography>
                        </Grid>
                        <Grid 
                        item xs={12} sm={6} md={4} lg={3} 
                        className={classes.boxes}
                        >
                            <img src={css3} alt="css3_logo"/>
                            <Typography className={classes.boxCaption}>CSS3</Typography>
                        </Grid>
                        <Grid 
                        item xs={12} sm={6} md={4} lg={3} 
                        className={classes.boxes}
                        >
                            <img src={javascript} alt="js_logo"/>
                            <Typography className={classes.boxCaption}>JavaScript</Typography>
                        </Grid>
                        <Grid 
                        item xs={12} sm={6} md={4} lg={3} 
                        className={classes.boxes}
                        >
                            <img src={linux} alt="linux_logo"/>
                            <Typography className={classes.boxCaption}>Linux</Typography>
                        </Grid>
                    </Grid>  
                    {/* First Two */}

                    <Grid item container spacing={3} direction="row" justify="space-evenly">
                        <Grid 
                        item xs={12} sm={6} md={4} lg={3} 
                        className={classes.boxes}
                        >
                            <img src={git} alt="git_logo"/>
                            <Typography className={classes.boxCaption}>Git</Typography>
                        </Grid>
                        <Grid 
                        item xs={12} sm={6} md={4} lg={3} 
                        className={classes.boxes}
                        >
                            <img src={mysql} alt="mysql_logo"/>
                            <Typography className={classes.boxCaption}>MySQL</Typography>
                        </Grid>
                        <Grid 
                        item xs={12} sm={6} md={4} lg={3} 
                        className={classes.boxes}
                        >
                            <img src={nodejs} alt="nodejs_logo"/>
                            <Typography className={classes.boxCaption}>Node.js</Typography>
                        </Grid>
                        <Grid 
                        item xs={12} sm={6} md={4} lg={3} 
                        className={classes.boxes}
                        >
                            <img src={npm} alt="npm_logo"/>
                            <Typography className={classes.boxCaption}>Npm</Typography>
                        </Grid>
                    </Grid>   
                </Grid> 
            </Box>                         
        </div>
    )
}

export default About
